npm install react-multi-carousel/* Breadcrumb css admin */
.common_dash .top h3 {
    font-weight: 600;
    font-size: 25px;
    display: flex;
}

.common_dash .anim {
    animation: myAnim 3s ease-in 1s 3 normal both;

}

@keyframes myAnim {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }

    10% {
        transform: rotate(8deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-10deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-8deg);
    }

    90% {
        transform: rotate(8deg);
    }
}

.common_dash .bread {
    display: flex;
    justify-content: end;
}

/* siteconfig css admin  */
.upshowing {
    background-color: #fff;
    padding: 20px;
    position: relative;

    & .pic {
        border-radius: 50%;
        height: 120px;
        width: 120px;

        & img {
            height: 120px;
            border-radius: 50%;
            width: 120px;
            object-fit: contain;
            border: 2px solid black;
            padding: 5px;
        }
    }

    & .upbtn {
        & button {
            font-size: 17px;
            background-color: #f5651d;
            color: #fff;
            border-radius: 20px;
            padding: 2px 10px;
            position: absolute;
            top: 66px;
            right: 46px;
        }
    }

    & h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 6px;
        border-bottom: 3px gray dashed;
        padding: 5px;

    }

    & p {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 1.5px;
        border-bottom: 1px solid gray;
        padding: 5px;
        height: 52px;

        &::before {
            content: " :  ";
        }
    }

    & .card_design {
        /* box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11); */
        /* box-shadow: 20px 0 10px -20px rgba(0,0,0,0.45) inset; */
        box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
        padding: 20px;
    }

    & .content p {
        height: 100px;
        overflow-y: auto;

    }

    & h5 {
        text-transform: capitalize;

    }

}

.upshowing .mrgin-bottom {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        background-color: #000;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
    }
}

.slider_page .table tr img {
    height: 90px;
    width: 100px;
    object-fit: contain;
    padding: 2px;
}

.comapnay_form .registerWrapper {
    padding: 0 73px 40px 50px;
    display: flex;
    flex-direction: row;
}

.comapnay_form .twm-tabs-style-2 {
    box-shadow: 0px 1px 1px 3px rgb(0 0 255 / 10%);
    border-radius: 10px;
    padding: 12px;
}

.comapnay_form .site-button {
    background-color: #f5651d;
    color: whitesmoke;
    border: none;
    border-radius: 5px;
    padding: 4px;
    height: 40px;
}

.ab-image img {
    width: 100%;
    height: auto;
    max-width: 450px;
}

@media (max-width:767px) {
    .main-container .sidebar {
        position: absolute;
        z-index: 2;
    }

    .centerbar {
        z-index: 1;
    }

    .upshowing .upbtn {
        & button {
            font-size: 14px;
            top: 64px;
            right: 35px;
        }

    }

    .upshowing ol {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.discription_content{
        height: 80px;
        overflow-y: scroll;
    }
    img#picsub {
        width: 125px;
        padding: 10px;
    }