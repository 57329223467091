* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#nav nav {
  height: 50px;
  width: 100%;
  margin: auto;
  align-items: center;
  margin-bottom: 25px;
}

body {
  background-image: url(../src/images/bluebf2.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

:root {
  /* --light-blue: rgb(233 249 255 / 95%); */
  --orange: rgb(245, 101, 29);
}

/*  MAin Container */
#root {
  background-color: var(--light-blue);
  overflow: hidden;
}

.main-container {
  display: flex;
}

/* Sidebar Start Here */

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.sidebar {
  background: white;
  color: black;
  height: 100vh;
  overflow-y: auto;
  /* position: fixed; */
  position: sticky;
  top: 0;
  left: 0;
}

.sidebar .top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.sidebar .top_section .logo {

  line-height: 0;
  border-radius: 5px;
}

.sidebar .top_section .bars {
  width: 30px;
  font-size: 18px;
}

#search {
  border-radius: 5px;
}

.hide {
  display: none;
}

.input-group #srh-icon {
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 20;

}

#search {
  padding-left: 35px;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: rgb(0, 7, 61);
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.routes .link {
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 10px;
  text-decoration: none;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.routes .link:hover {
  border-right: 4px solid whitesmoke;
  background: rgb(101 193 232);
  color: #f1f1f1;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.routes .active {
  border-right: 4px solid rgb(154 212 236);
  color: white;
  background: rgb(224 117 10);
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: black;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

/* ::-webkit-scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/*  Centerbar starts here  */

/* topbar  */
.topbar {
  width: 100%;
  position: sticky;
  top: 0;
  background: rgb(154 212 236);
  line-height: 50px;
  z-index: 99;
  color: white;
}

.topbar .H1 {
  font-size: 25px;
  font-weight: 900;
  display: contents;
}

.centerbar {
  /* width: 83%; */
  width: 100%;
  overflow-y: auto;
}

.card-height {
  min-height: 150px;
  border-radius: 5px;
  backdrop-filter: blur(10px);

}

/* settings */
.profile .lable {
  padding: 5px 10px;
  background-color: var(--orange);
  color: whitesmoke;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.profile .lable .image-upload {
  font-size: 25px;
  font-weight: bold;
}

.profile .img-priview img {
  width: 200px;
  height: 150px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.imgaddmain {
  display: flex;
  justify-content: center;
}

.category-container {
  display: flex;
  justify-content: space-between;
}

.category-container h4 {
  margin-right: 10px;
}

/* action btn */
td.action {
  display: flex;
}

td.action.pivoted {
  justify-content: center;
}

table.table.table-striped.table-hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-collapse: separate;
  text-align: center;
  /* box-shadow: 0px 8px 12px rgba(60, 64, 67, 0.15), 0px 4px 4px rgba(60, 64, 67, 0.3); */
}

.lineclamptbl {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}