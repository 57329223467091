@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  padding-left: 0 !important;
}

p {
  margin-bottom: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: white;
  text-decoration: none;
}

strong {
  font-weight: bolder;
  color: #ec801cdb;
}

.navbar-nav .nav-link.active {
  color: #ec801cdb;
  background-color: #4db8e04d;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
}

ul.navbar-nav.mx-auto.mb-2 {
  gap: 10px;
}

.navsearchbar {
  border-radius: 20px;
  box-shadow: none !important;
  font-size: 1rem;
  padding: 3px 10px !important;
  margin-top: 5px;
  position: absolute;
  width: 250px;
}

span.navsrchicn {
  position: relative;
  top: 10px;
  left: 215px;
  display: inline-flex;
}

.main-header {
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 8;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 0px 0px 54px 54px;
}

.dropdown-menu {
  display: none;
  position: absolute;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

button.explore.button {
  margin-top: 6px;
  padding: 3px;
  border: none;
  background-color: transparent;
}

.explore {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

.heading {
  text-align: center;
  margin: 30px 0;
  font-size: 1.2rem;
  font-weight: 700;

  & p {
    margin-top: 38px;
  }
}

#drhlink {
  font-weight: 600;
  color: black;
}

#drhlink:hover {
  color: #F4881F;
}

.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 225px;
}

.explorecourse {
  width: 500px;
  background-color: rgb(248, 250, 250);
  position: absolute;
  display: flex;
  padding: 4px;
  margin-top: 78px;
  border-bottom: 1px solid #ddd;
  border-radius: 8px;
  z-index: 1;
  position: fixed;
}

/* navline */
.nav-link:hover {
  color: #EE9B49;
  background-color: #C8E4EF;
}

.nav-link.menubar.nav-text ::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  border-radius: 10px;
  bottom: 0;
  left: 0;
  background-color: #f77e53;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s ease-in-out;
}

.CExpertbtn {
  text-align: center;
}

.expertbtn {
  font-size: 1rem;
  font-weight: 600;
  border-radius: 20px;
  padding: 5px 10px;
  margin-top: 20px;
  color: whitesmoke;
  outline: none;
  border: none;
  box-shadow: 0 1px 2px #000;
  background-color: #f7841c;
}

.exp.col-lg-6.mt-3 {
  max-height: 310px;
  overflow-y: scroll;
}

.de1 {
  margin-inline: auto;
  margin-top: 3px;
}

.de2 {
  margin-inline: auto;
}

.modal.show .modal-dialog {
  transform: none;
  max-width: 600px;
}

.cards {
  background-color: #c3dced;
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 12px;
  padding: 0px;
  align-items: center;
}

b.expbtnhead {
  color: #EE9B49;
  text-decoration: underline;
}

a.dropdown-item:hover {
  color: #F78321;
}

.Banner {
  width: 100%;
  height: auto;
  box-shadow: #0000001c 0px 25px 20px -20px;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  /* background-color: rgb(221, 221, 221); */
}

.Banner .heading {
  text-align: left;
  line-height: 20px;
  text-transform: uppercase;
  font-style: italic;
  margin-top: 100px;

  & h3 {
    font-size: 1.5rem;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
  }

  & p {
    padding: 0;
    margin: 0;

    & strong {
      font-size: 1rem;
      font-weight: 600;
      color: #070809;
    }
  }
}
.bnr{
  position: absolute;
}
.Banner .main-img {
  width: 100%;
  height: 370px;
  object-fit: contain;
  animation-name: up-down;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes up-down {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: 10px;
  }

  75% {
    margin-top: 5;
  }

  100% {
    margin-top: 0;
  }
}

.Banner button.startbtn {
  background-color: #0d3f5a;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-top: 50px;
}

.skills {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 20px;

  & .btnskills {
    background-color: #f69d0ee9;
    margin-top: 10px;
    border: none;
    height: 40px;
    width: auto;
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

    & b {
      padding: 5px 10px;
      color: white;
    }
  }

  .firstbtn {
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    padding: 5px 12px 0 0;
    font-size: 0.7rem;
    font-weight: 900;
    background-color: #0d3f5a;
    color: #fff;
    border-radius: none;

    &:hover {
      background-color: #f69d0ee9;
      color: #0d3f5a;
    }
  }
}

h2.tc {
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
}

.tccards {
  display: flex;
  margin: 2px;
}

.tccards .ccards {
  width: 280px;
  height: 145px;
  background-color: rgb(247 238 247 / 70%);
  border-radius: 8px;
  border: 3px inset;
  text-align: center;
  margin: auto;
  cursor: pointer;
}

.tccards .ccards img {
  border-radius: 50%;
  width: 100px;
  float: inline-start;
  cursor: pointer;
}

.training-section {
  width: 100%;
  padding-bottom: 50px;
  background-size: cover;
}

.training-section h1 {
  margin-top: 23px;
  text-align: center;
  padding: 25px 0px;
  color: #ff8c00;
  font-family: ui-rounded;
  text-decoration: underline;
}

.container.allcards {
  margin: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.traning-items {
  flex-basis: 23%;
  text-align: center;
  margin: 10px 0px;
  box-shadow: 0px 0px 5px 1px lightgray;
  background: white;
  padding: 0 7px 10px 7px;
  border-radius: 6px;
  min-width: 264px;
  height: 400px;

  & .crdsoverview {
    height: 50px;
  }
}

.traning-items:hover {
  transition: 500ms ease-in-out;
  box-shadow: 5px 7px 15px 2px rgba(82, 90, 101, 0.12);
  transform: translate(4px, 4px);
  /* transform: translateY(-10px); */
}

.traning-items h3 {
  padding: 5px 0px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  white-space: pre-line;
}

.traning-items h3:hover {
  color: #ff8c00;
}

.traning-items p {
  font-size: 14px;
  padding: 5px;
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 0px !important;
}

.traning-items button {
  padding: 7px 13px;
  border-radius: 10px;
  margin: 2px;
  background-color: #c3dced;
  border: none;
}

.traning-items button#jbtn:hover {
  background-color: rgb(0, 255, 106);
}

.traning-items img {
  object-fit: contain;
  height: 100px;
  width: 100px;
  padding: 8px;
  margin-top: 10px;
  box-shadow: 7px 7px 14px #cfcacaad;
  border-radius: 50%;
}

/* ///////////////////////////////////////// */

.features {
  width: 100%;
  height: auto;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & img {
    width: 300px;
    height: 280px;
    border-radius: 50%;
    padding: 10px;
    background-color: #4158d0;
    background-image: linear-gradient(43deg,
        #4158d0 0%,
        #c850c0 46%,
        #ffcc70 100%);
  }

  & ul {
    list-style: none;
  }
}

.list ul {
  list-style: none;
  margin-top: 30px;

  & li {
    display: grid;
    place-items: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    padding: 10px;
    margin-top: 5px;
    background-color: #c3dced;
    border-radius: 30px;

    &:hover {
      color: white;
      background-color: #f18721;
      font-style: oblique;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
}

.features-second {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  & .list ul {
    list-style: none;
    margin-top: 30px;

    & li {
      display: grid;
      place-items: center;
      /* width: 350px; */
      font-size: 1rem;
      font-weight: 600;
      line-height: 15px;
      padding: 10px;
      margin-top: 12px;
      background-color: #c3dced;
      border-radius: 30px;

      &:hover {
        color: white;
        background-color: #f18721;
        font-style: oblique;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
}

.features-list {
  width: 100%;
  display: flex;
  justify-content: center;

  & ul {
    list-style: none;

    & li {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 10px;
      font-size: 1rem;
      font-weight: 500;
      line-height: 40px;
      margin: 0 auto;
    }
  }
}

/*///////////////////////////////////////////////  */

@media only screen and (max-width: 768px) {
  .features {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.traning-items .Cdiv {
  text-align: center;
  margin: 10px;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  padding: 6px;
  display: flex;
  border-radius: 14px;
  background-color: #80808038;
}

#vbtn {
  margin-left: 10px;
  color: #0295c8;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.6s all;

  &:hover {
    color: white;
    background-color: #ea8321;

    /* background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%); */
  }
}

.traning-items text {
  text-shadow: 0px 1px #ff8100;
  font-weight: 700;
  color: #0295c8;
}

/*  Registration  */
.registration .rheading {
  text-align: center;
  margin-top: 15px;
  text-decoration: underline;
}

h4#rhding {
  text-align: center;
}

.registration form input[type="text"] {
  width: 50%;
  padding: 6px 20px;
  margin: 4px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
}
.registration form label {
  width: 50%;
  margin: 4px 0;
  box-sizing: border-box;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.registration form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.phone {
  width: 100%;
}

.PhoneInput {
  display: flex;
  width: 1080px;
  margin-left: 429px;
}

svg.PhoneInputCountryIconImg {
  max-width: 40px;
}

select.PhoneInputCountrySelect {
  max-width: 80px;
}

#loginform {
  background-color: #42b0d5;
}

.loginform.col-md-5.col-lg-5 {
  margin: 5px 0px 5px;
}
.contact_form {
  border: 2px solid gray;
  border-radius: 12px;
  padding: 8px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding:5px;
  & #mesgeicn{
  padding:66px 0px;
  }
}
.form-control:focus{
  box-shadow: 1px 2px 0px 0px orange;
}
.signbtn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.signbtn {
  background-color: #f7841c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  display: flex;
  margin: auto;
  margin-top: 20px;
}

input#backendradio {
  margin-left: 12px;
}

.registerbtn {
  background-color: #f7841c;
  color: #fff;
  padding: 5px 12px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  display: flex;
  margin: auto;
}

.rgheading {
  font-size: 1.5rem;
  margin-top: 40px;
  text-decoration: underline;
}

.login-signup-card {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  padding: 20px;

  & h3 {
    font-size: 1.5rem;
  }
}
/* Admin Login */
.card.adminLogin.mt-5.col-lg-6 {
  margin: auto;
  padding: 0px 30px;
  box-shadow: 0px 0px 3px 0px;
}
/* login */
label.rgform {
  font-weight: 500;
  font-size: 15px;
  margin-left: 5px;
} 
.input-icon {
  z-index: 1;
  font-size: 25px;
  background-color: #e8eaea;
  height: 37px;
  align-items: center;
  border: 1px solid #e0d5d5;
  display: flex;
  width: 50px;
  justify-content: center;
  border-radius: 10px 0px 0px 10px;
  &:hover {
    color: #F4881F;
  }
}
/* login */
/* Single--page */

.singlepge .heading {
  text-decoration: underline;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.spcontent {
  text-align: justify;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  line-height: 25px;
  margin-left: 10px;

  & ul {
    list-style: square;

    & li {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: centerF;
    }
  }
}

.spcontents {
  display: flex;
  flex-direction: column;
  border-style: hidden;

  & ul {
    list-style: none;
  }
}

.spcontent li:hover .Collapsible__trigger {
  color: #f7841c;
  cursor: pointer;
}

p.sponline {
  line-height: 35px;
  margin-bottom: 30px;
  padding: 0;
}

div#sponline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-video {
  margin-inline-end: 30px;
  margin: 5px 30px;
}

.spcontent_last_data {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: #99c4d36a;

  & .first {
    margin: 10px 0;
    box-shadow: rgba(125, 7, 7, 0.822) 0px 0px 3px 1px;
    padding: 10px;

    .price h5,
    .duration h5 {
      font-size: 1.5rem;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
    }

    .price h5 strong,
    .duration h5 strong {
      font-size: 1.5rem;
      font-weight: 600;
      margin-left: 20px;
      color: #f69d0ee9;
    }
  }
}

.list {
  width: 100%;
}

button.spj {
  border-radius: 30px;
  height: 60px;
  padding: 0px 24px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    color: #e6e6fa;
    background-color: #00dbde;
    background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);
  }
}

/* fsubcategory */

.subcateogory {
  border-top: 1px solid #d1d7dc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: auto;
  padding: 20px 0;
  background-color: white;
  z-index: 1009;
  position: fixed;
  top: 0;
  left: 0;

  & .clippath {
    background-color: black;
    clip-path: polygon(57% 0, 91% 51%, 59% 100%, 31% 100%, 62% 49%, 30% 0);
    font-size: 22px;
    width: 40px;
    height: 20px;
    line-height: 0.5rem;
    margin-right: 10px;
    border: none;
    outline: none;

    &:hover {
      background-color: red;
      color: purple;
    }
  }
}

.subtn {
  font-size: 1rem;
  font-weight: 400;
  padding: 5px 15px;
  margin-left: 8px;
  line-height: 1rem;
  border-radius: 10px 20px;
  border: none;
  outline: none;

  &:hover {
    color: #00dbde;
  }
}

button.Subbutton-main {
  border: none;
  outline: none;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 5px 10px;
  line-height: 1rem;
  border-radius: 10px 20px;
  /* background-color: transparent; */
}

/*sp-top Banner  */
.bner-sp {
  color: #fff;
  background-color: #2d2f31;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08), 0 -4px 12px rgba(0, 0, 0, 0.16);
  padding: 0;
}

.bner-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 11px;
  position: fixed;
  background-color: black;
  top: 65px;
  left: 0;
  & .title {
    display: block;
    margin-right: 1.6rem;
    flex: 1;
    min-width: 0;
  } 
  & .title-text {
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    margin-bottom: 0.4rem;
  }
}

.badge {
  color: #f68013;
  background-color: #c5e1ed;
}

.badge-rating {
  display: flex;
  line-height: 1.4rem;
}

.banner-rating {
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.4rem;
}

.star::after {
  content: "☆☆☆☆☆";
  color: #f4881f;
}

.start {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.banner-sp-inerheading {
  background-color: #2d2f31;
  color: #fff;
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 35px;
  z-index: 1000;
}

div#banertext {
  line-height: 2;
}

.bner-sp-heading {
  font-size: 0.3rem;
  margin-bottom: 0.8rem;
}

.banner-sp-inerheadingsmall {
  font-size: 1.9rem;
  margin-bottom: 1.6rem;
}

.banner-created {
  flex-direction: row;
  align-items: center;
}

.created {
  display: flex;
}

.text-link {
  display: block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex: 1 1;
  min-width: 1px;
  text-decoration: underline;
  color: #fd8414;
  font-weight: 700;
}

.contentsp {
  width: 75%;
}

.sp-tabsform {
  background-color: whitesmoke;
  box-shadow: 1px 2px 4px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  float: right;
  position: fixed;
  top: 15%;
  right: 5px;
  z-index: 2000;
}

.cross-btn {
  position: absolute;
  right: 20px;
  top: 10px;

  & .crossIcon:hover {
    color: red;
  }
}

.second-team {
  margin-left: 10px;
}

ul.nav.nav-tabs {
  background-color: white;
  border-radius: 2px;
  display: inline-flex;
}

.tabs-heading {
  text-align: center;
  /* font-size: larger;/ */
  font-size: 1rem;
  font-weight: 600;
  margin-top: 5px;
  color: #f77800;
}

#tabs-lernmore {
  font-size: 1rem;
  font-weight: 600;
  color: #fd8414;
  text-decoration: underline;
}

.tabs-p {
  padding: 5px 20px;
  text-align: center;
}

.tabs-start {
  color: #fd8414;
  background-color: #0098c85e;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  border: none;
}

.or {
  text-align: center;
  font-size: medium;
  font-weight: 600;
  padding: 2px;
}

.or::before {
  content: "__________";
}

.or::after {
  content: "__________";
}

.tabsprice {
  font-family: var(--font-stack-heading);
  font-weight: 700;
  /* line-height: 1.2; */
  /* letter-spacing: 0; */
  font-size: 1.5rem;
  max-width: 36em;
}

button.buytabs {
  outline: none;
  padding: 0 3px;
  font-weight: 600;
  border-radius: 5px;
  color: #fd8414;
  text-align: center;
  border-radius: 12px;
}

.tab {
  flex-direction: column;
  display: flex;
}

/* Course  css start */
.course_1 {
  & li {
    list-style: none;
    font-size: 1rem;
    padding: 5px 0;

    & strong {
      color: red;
    }
  }
}

.course_2 {
  & li {
    display: flex;
    align-items: center;
    gap: 10px;
    list-style: none;
    font-size: 1rem;
    padding: 5px 0;
  }
}

.courseHeading {
  font-weight: 600;
  text-decoration: underline;
  margin: 20px 0;
}

/* Course  css end */

/* footer */
.footer__btm {
  width: 100%;
  color: white;
  background-color: #0d3f5a;
  margin-top: 50px;
  font-weight: 400;
}

.footer__menu {
  & h4 {
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
  }

  & ul li { 
    line-height: 32px;
    & a{
      font-size: 0.8rem;
      font-family: "Poppins", sans-serif;
      &:hover {
        color: #F88323;
        padding-left: 2px;
        transform:all 2s;
      }
    } 
  }
  
  & h4.fhead {
    font-weight: 900;
    border-bottom: dashed;
    justify-content: center;
    display: flex;
} & h3.fmh {
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
}& .img-fluid{
height:80px !important;
}
}

.footer__copyright {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  background-color: #000;
  color: white;
}

.footer-icon i {
  font-size: 1.7rem;
  color: white;
  margin-left: 20px;

  &:nth-child(1):hover {
    color: #4267B2;

  }

  &:nth-child(2):hover {
    color: #ee2a7b;

  }

  &:nth-child(3):hover {
    color: #1DA1F2;

  }

  &:nth-child(4):hover {
    color: red;

  }

  &:hover {
    color: #F88323;
    border-radius: 10px;
  }
}
/* responsive */
@media (max-width: 995px) {
  .features img {
    width: 250px;
    height: 250px;
  }

  .bner-heading {
    top: 70px;
  }

  .Banner .heading {
    & h3 {
      font-size: 1.2rem;
    }

    & p strong {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 880px) {
  .bner-heading {
    top: 90px;
  }

  .banner-sp-inerheading {
    top: 60px;
  }
}

@media (max-width: 768px) {
  .sp-video {
    display: none;
  }

  .contentsp {
    width: 100%;
  }

  .spcontent_last_data {
    & .first {

      & .duration h5,
      .price h5 {
        font-size: 1rem;
      }

      & .duration h5 strong,
      .price h5 strong {
        font-size: 1rem;
      }
    }

    .spj {
      padding: 5px 10px;
      font-size: 0.8rem;
    }
  }

  .bner-heading {
    top: 90px;
  }

  .banner-sp-inerheading {
    top: 65px;
  }

  .Banner .heading {
    & h3 {
      font-size: 0.9rem;
    }

    & p strong {
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 576px) {
  .features .images {
    display: flex;
    justify-content: center;
  }

  .skills {
    text-align: center;
    top: -50px;

    & .btnskills {
      width: 250px;
    }
  }
}

@media (max-width: 486px) {
  .bner-heading {
    top: 110px;
  }

  .banner-sp-inerheading {
    top: 90px;
  }
}

@media (max-width: 360px) {
  .bner-heading {
    top: 75px;
  }
}
@media only screen and (max-width:426px) {
  .position-absolute {
    display: none;
  }
 .skills{
 top: 0px;
 }
 & .slidermain {
    background-color: #85cae514;
    margin-left: -30px !important;
}
}
/* pricing page */
.section_title {
  text-align: center;
  padding: 30px;
  padding-bottom: 10px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: blue;
    bottom: 0;
    left: calc(50% - 25px);
  }
}

.section_title p {
  text-align: center;
  padding-top: 5px;
}

hr.hrupdateimg {
  margin-top: 90px;
}

td.linetble.pivoted {
  display: flex;
  block-size: 150px;
  overflow-y: scroll;
  width: 300px;
}

/* Pricing cards */
.container.main_price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card-basic,
.card-premium,
.card-standard {
  margin: 5px 1rem;
  padding: 0 0 0.5rem 0;
  width: 20rem;
  background: #fff;
  color: #444;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
}

.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.02);
}

.card-header {
  height: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  padding: 1rem 0;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
}

.header-basic
/* .btn-basic */
 {
  background: linear-gradient(135deg, rgb(0, 119, 238), #06c766);
}

.header-standard,
.btn-standard {
  background: linear-gradient(135deg, #b202c9, #cf087c);
}

.header-premium,
.btn-premium {
  background: linear-gradient(135deg, #eea300, #ee5700);
}

.card-body {
  padding: 0.5rem 0;
}

.card-body h2 {
  font-size: 2rem;
  font-weight: 700;
}

.card-element-container {
  color: #444;
  list-style: none;
}

.btn {
  margin: 0.5rem 0;
  padding: 0.7rem 1rem;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  /* color: #fff; */
  border: none;
  cursor: pointer;
  transition: all 0.1ms ease-in-out;
}

.btn:hover {
  transform: scale(0.95);
}

.btn:active {
  transform: scale(1);
}

.card-element-hidden {
  display: none;
}

p.pricecrdtxt {
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  margin-left: 2px;
}
.pitoverview{
  overflow:hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.pricingimgcrds {
  display: flex;
  justify-content: center;
  height: 95px;
}

p.crprs {
  font-size: 30px;
  color: #FB851C;
  font-weight: 700;
  animation: crps 15s infinite;
}

.pricinghead {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 130px;
  font-size: xx-large;
}
.pbtn {
    background-color: #dfdfdff5;
}
/* Pricing Cards */
/* billing  */
span.bhding {
  font-size: 18px;
  font-weight: 700;
}

img.imgcrse {
  height: 180px;
  width: 160px;
  object-fit: contain;
}

.bsummary.col-lg-6.col-md-6 {
  display: flex;
  justify-content: end;
  align-items: center;
}

.bilheading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-bottom: gray 2px solid;
}

.aboutcrse.py-4 {
  line-height: 35px;
  margin-inline: 10px;
}

.bildetl {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
}

button.bilbtn {
  border-radius: 20px;
  padding-inline: 15px;
  background-color: bisque;
}

.container.billing {
  margin-top: 38px;
}

/* payments */
img.pymentimg {
  width: 100px;
}

div#cardpayment {
  display: flex;
  justify-content: center;
  align-items: center;
}

form.row.card {
  line-height: 2;
}

/* Billing */

/* tabel */
/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F7861F;
}

/* scroll bar */
/* srchbarpge */
span.sicon {
  font-size: 30px;
  margin-right: 40px;
}

input.srch {
  background-color: whitesmoke;
  padding: 5px;
  outline: none;
  border-radius: 7px;
  width: 100%;
  box-shadow: 5px 7px 15px 2px rgba(82, 90, 101, 0.12);
}

.modalsrch {
  max-height: 250px;
  overflow: auto;
}

a.small {
  color: blue;
}

.nav-link .menubar:before {
  content: '';
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 3px;
  background-color: yellow;
  transform: scale(0);
  transform-origin: 50%;
}

.nav-link .menubar:before:hover {
  transform: scale(1);
}

/* dashboard */
.crsedtail.col-lg-6 {
  line-height: 55px;
  text-align: start;
}

.error-box {
  & .errhead {
    color: orange;
    justify-content: center;
    display: flex;
  }

  & .h2 {
    justify-content: center;
    display: flex;
    color: #4158d0
  }

}

img.errimg {
  width: 300px;
  float: inline-start;
}

img.puser {
  width: 140px;
  display: flex;
  float: inline-start;
  border: 10px inset;
  border-radius: 50%;
  padding: 4px;
}

.profildiv {
  display: inline-grid;
  justify-content: space-around;
  width: 40%;
  margin-left: 30px;
  padding: 4px;
}

.course {
  display: flex;
  justify-content: space-evenly;
}

span.cntent {
  font-weight: 400;
  text-decoration: underline;
  border-bottom: solid;
  font-size: larger;
}

a.brlink {
  color: blue;
}

button.btn.editicn {
  font-size: 30px;
  color: green;
  animation-timing-function: ease-in;

  &.btn.editicn:hover {
    color: #FC821E;
  }
}

button.btn.dlticn {
  font-size: 30px;
  color: hsl(14, 73%, 41%);
  animation-timing-function: ease-in;

  &.btn.dlticn:hover {
    color: #FC821E;
  }
}

/* features page */
.feture {
  & .fmain {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
    padding: 5px;
  }

  & .fhding {
    justify-content: center;
    display: flex;
    color: white;
  }
}

.fcontent {
  font-size: 4rem;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  font-family: auto;
  padding: 20px;
}

.gallery-image {
  width: 200px;
  height: 150px;
  object-fit: contain;
  margin-right: 10px;
  padding: 5px;
}

.carsousel {
  padding: 30px 0px 0px 0px;
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  vertical-align: top;
  white-space: normal;
  border: 2px outset;
  line-height: 12 !important;
  border-radius: 5px;
}

.row.feature {
  display: contents;
}

.modal-content {
  width: 600px;
  max-height: 350px;
  overflow-x: auto;
}

.bts {
  font-size: 17px;
  background-color: #f5651d;
  color: #fff;
  border-radius: 20px;
  padding: 2px 10px;
  position: absolute;
  right: 30px;
}

/* Loader */

.loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader:before,
.loader:after {
  content: '';
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  border-radius: 50%;
  color: #656464;
  background: currentColor;
  box-shadow: 50px 0, -50px 0;
  animation: left 1s infinite ease-in-out;
}

.loader:after {
  color: #F4881F;
  animation: right 1.1s infinite ease-in-out;
}

@keyframes right {

  0%,
  100% {
    transform: translateY(-10px)
  }

  50% {
    transform: translateY(10px)
  }
}

@keyframes left {

  0%,
  100% {
    transform: translateY(10px)
  }

  50% {
    transform: translateY(-10px)
  }
}

span#crds {
  display: flex;
  justify-content: center;
}

/* contactpage */

.iconcontact {
  font-size: 30px;
  color: #0397C1;

  &:hover {
    color: #F4881F;
  }
}

p.contacttext {
  font-size: 25px;
  font-weight: 500;
  line-height: 2;

  &:hover {
    color: #F4881F;
  }
}

.dtcontact {
  font-size: 16px;
  font-weight: 600;
  text-decoration: auto;
  color: #0393CC;

  &:hover {
    color: #F4881F;
  }
}

.cntsdow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  background-color: whitesmoke;
}

.cimgfull.col-lg-6.col-md-6.col-sm-12 {
  display: flex;
  justify-content: center;
}

.cfrm {
  display: flex;
  justify-content: center;
}

iframe {
  width: inherit;
}

/* payment form */
.payment {
  flex-direction: column;
}

.securedcard {
  width: 60%;
}

/* loginfirst */
img.licn {
  width: 300px;
  object-fit: contain;
}

.popupdegn {
  display: flex;
  align-items: center;
  justify-content: center;
}

p.popctnt {
  font-size: 25px;
  font-weight: 400;
  font-family: none;
}

/* animination */
.maniheadingpge {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 30%;
  }

  to {
    margin-left: 0%;
  }
}

/*  */
.animationwave {
  animation: wave 3s linear infinite;
  width: 1500px;
  transition: all 10s ease-in;

}

@keyframes wave {
  0% {
    transform: translateX(-20px);
  }

  50% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-20px);
  }
}

body {
  overflow-x: hidden;
}

.wave-main {
  position: relative;
  z-index: 0;
  top: -20px;
}

.wave-display {
  display: flex;
  position: relative;
  bottom: 200px;
  justify-content: center;
}

.container.wavi {
  margin-top: -130px;
}

.tcdton-li-item {
  list-style: none
}

.tshead {
  font-weight: 700;
  -webkit-text-stroke-width: thin;
  text-decoration: underline;
}
.breadcrumb-item {
  & .brlink:hover{
  color:orange;
  }
}
.terms {
  color: blue;
  transform: scale(0.6);
  font-size: larger;
  position: absolute;
  top: -5px;
  left: -1%;
  animation: text 5s linear infinite;
  transition: all 5s ease-in;
}

@keyframes text {
  0% {
    transform: translateX(-2px);
    color: rgb(234, 125, 16);
  }

  100% {
    transform: translateX(15px);
    color: rgb(23, 152, 203);
  }
}

.MuiTableCell-body-60 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px !important;
  font-weight: 400;
}

img.tbimg {
  width: 80px;
}
/* why choose us */
.whychooseus {
  background-color: #d4d9dd6e;
  margin-top: 32px;
  border-radius: 15px;
  padding: 20px;
}
.imageswcu {
  width: 135px;
  height: 100px;
  border-radius: 50%;
  padding: 0px 12px 0px 12px;
}
.wcscenter img{
  border-radius: 20px;
  opacity: 0.9;
  &:hover{
    transition: ease-in-out 0.9s;
    transform: scale(1.1);  
  }
}
.wcsicn{
font-size: 60px;
margin-left: 10px;
display: flex;
color:#f7841cc9;
}
/* joinnow */
.mainjn{
  background-image: -webkit-linear-gradient(0deg, #f98521d9 0%, #f9852199 100%)
}
.joinnow{
  margin: 35px 5px 0px 5px;
  padding: 10px;
  border: none;
  color: white;
  background-color: #0f94c3;
}
/* slider */
.slidermain{
  background-color: #85cae514;
}
.slick-slide{
  margin:0px 0px 0px 10px;
}
.slick-list {
  height:176px;
}
.slick-prev:before {
  content: '←';
  color: #F68423 !important;
}
.slick-next:before {
  content: '→';
  color: #F68423 !important;
}
.sliderimges{
  aspect-ratio:3/4;
  width:80px;
  height: 80px;
  object-fit: contain;
  border-radius:50%;
  padding: 10px;
  box-shadow: 10px 10px 20px rgb(209, 206, 206);
  margin: 15px 0;
  transition: all .2s ease-in-out;
  &:hover{
    transform: scale(1.1); 
   }
}

.slidertext {
  text-align: center;
  font-weight: 600;
  color: #27A4CB;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  &:hover{
color:#F6851E;
  }
}
.shrpcode {
  text-align: center;
  text-wrap: balance;
}